const countryList = {
    "Europe": [
        {
            "name": "Albanie",
            "capital": "Tirana",
            "code": "al"
        },
        {
            "name": "Allemagne",
            "capital": "Berlin",
            "code": "de"
        },
        {
            "name": "Andorre",
            "capital": "Andorre-la-Vieille",
            "code": "ad"
        },
        {
            "name": "Autriche",
            "capital": "Vienne",
            "code": "at"
        },
        {
            "name": "Belgique",
            "capital": "Bruxelles",
            "code": "be"
        },
        {
            "name": "Biélorussie",
            "capital": "Minsk",
            "code": "by"
        },
        {
            "name": "Bosnie-Herzégovine",
            "capital": "Sarajevo",
            "code": "ba"
        },
        {
            "name": "Bulgarie",
            "capital": "Sofia",
            "code": "bg"
        },
        {
            "name": "Croatie",
            "capital": "Zagreb",
            "code": "hr"
        },
        {
            "name": "Danemark",
            "capital": "Copenhague",
            "code": "dk"
        },
        {
            "name": "Espagne",
            "capital": "Madrid",
            "code": "es"
        },
        {
            "name": "Estonie",
            "capital": "Tallinn",
            "code": "ee"
        },
        {
            "name": "Finlande",
            "capital": "Helsinki",
            "code": "fi"
        },
        {
            "name": "France",
            "capital": "Paris",
            "code": "fr"
        },
        {
            "name": "Grèce",
            "capital": "Athènes",
            "code": "gr"
        },
        {
            "name": "Hongrie",
            "capital": "Budapest",
            "code": "hu"
        },
        {
            "name": "Irlande",
            "capital": "Dublin",
            "code": "ie"
        },
        {
            "name": "Islande",
            "capital": "Reykjavik",
            "code": "is"
        },
        {
            "name": "Italie",
            "capital": "Rome",
            "code": "it"
        },
        {
            "name": "Kosovo",
            "capital": "Pristina",
            "code": "xk"
        },
        {
            "name": "Lettonie",
            "capital": "Riga",
            "code": "lv"
        },
        {
            "name": "Liechtenstein",
            "capital": "Vaduz",
            "code": "li"
        },
        {
            "name": "Lituanie",
            "capital": "Vilnius",
            "code": "lt"
        },
        {
            "name": "Luxembourg",
            "capital": "Luxembourg",
            "code": "lu"
        },
        {
            "name": "Macédoine du Nord",
            "capital": "Skopje",
            "code": "mk"
        },
        {
            "name": "Malte",
            "capital": "La Valette",
            "code": "mt"
        },
        {
            "name": "Moldavie",
            "capital": "Chisinau",
            "code": "md"
        },
        {
            "name": "Monaco",
            "capital": "Monaco",
            "code": "mc"
        },
        {
            "name": "Monténégro",
            "capital": "Podgorica",
            "code": "me"
        },
        {
            "name": "Norvège",
            "capital": "Oslo",
            "code": "no"
        },
        {
            "name": "Pays-Bas",
            "capital": "Amsterdam",
            "code": "nl"
        },
        {
            "name": "Pologne",
            "capital": "Varsovie",
            "code": "pl"
        },
        {
            "name": "Portugal",
            "capital": "Lisbonne",
            "code": "pt"
        },
        {
            "name": "République tchèque",
            "capital": "Prague",
            "code": "cz"
        },
        {
            "name": "Roumanie",
            "capital": "Bucarest",
            "code": "ro"
        },
        {
            "name": "Royaume-Uni",
            "capital": "Londres",
            "code": "gb"
        },
        {
            "name": "Russie",
            "capital": "Moscou",
            "code": "ru"
        },
        {
            "name": "Saint-Marin",
            "capital": "Saint-Marin",
            "code": "sm"
        },
        {
            "name": "Serbie",
            "capital": "Belgrade",
            "code": "rs"
        },
        {
            "name": "Slovaquie",
            "capital": "Bratislava",
            "code": "sk"
        },
        {
            "name": "Slovénie",
            "capital": "Ljubljana",
            "code": "si"
        },
        {
            "name": "Suède",
            "capital": "Stockholm",
            "code": "se"
        },
        {
            "name": "Suisse",
            "capital": "Berne",
            "code": "ch"
        },
        {
            "name": "Ukraine",
            "capital": "Kiev",
            "code": "ua"
        },
        {
            "name": "Vatican",
            "capital": "Vatican",
            "code": "va"
        }
    ],
    "Asie": [
        {
            "name": "Afghanistan",
            "capital": "Kaboul",
            "code": "af"
        },
        {
            "name": "Arabie saoudite",
            "capital": "Riyad",
            "code": "sa"
        },
        {
            "name": "Arménie",
            "capital": "Erevan",
            "code": "am"
        },
        {
            "name": "Azerbaïdjan",
            "capital": "Bakou",
            "code": "az"
        },
        {
            "name": "Bahreïn",
            "capital": "Manama",
            "code": "bh"
        },
        {
            "name": "Bangladesh",
            "capital": "Dacca",
            "code": "bd"
        },
        {
            "name": "Bhoutan",
            "capital": "Thimphou",
            "code": "bt"
        },
        {
            "name": "Birmanie",
            "capital": "Naypyidaw",
            "code": "mm"
        },
        {
            "name": "Brunei",
            "capital": "Bandar Seri Begawan",
            "code": "bn"
        },
        {
            "name": "Cambodge",
            "capital": "Phnom Penh",
            "code": "kh"
        },
        {
            "name": "Chine",
            "capital": "Pékin",
            "code": "cn"
        },
        {
            "name": "Chypre",
            "capital": "Nicosie",
            "code": "cy"
        },
        {
            "name": "Corée du Nord",
            "capital": "Pyongyang",
            "code": "kp"
        },
        {
            "name": "Corée du Sud",
            "capital": "Séoul",
            "code": "kr"
        },
        {
            "name": "Émirats arabes unis",
            "capital": "Abou Dabi",
            "code": "ae"
        },
        {
            "name": "Géorgie",
            "capital": "Tbilissi",
            "code": "ge"
        },
        {
            "name": "Inde",
            "capital": "New Delhi",
            "code": "in"
        },
        {
            "name": "Indonésie",
            "capital": "Jakarta",
            "code": "id"
        },
        {
            "name": "Irak",
            "capital": "Bagdad",
            "code": "iq"
        },
        {
            "name": "Iran",
            "capital": "Téhéran",
            "code": "ir"
        },
        {
            "name": "Israël",
            "capital": "Jérusalem",
            "code": "il"
        },
        {
            "name": "Japon",
            "capital": "Tokyo",
            "code": "jp"
        },
        {
            "name": "Jordanie",
            "capital": "Amman",
            "code": "jo"
        },
        {
            "name": "Kazakhstan",
            "capital": "Astana",
            "code": "kz"
        },
        {
            "name": "Kirghizistan",
            "capital": "Bichkek",
            "code": "kg"
        },
        {
            "name": "Koweït",
            "capital": "Koweït",
            "code": "kw"
        },
        {
            "name": "Laos",
            "capital": "Vientiane",
            "code": "la"
        },
        {
            "name": "Liban",
            "capital": "Beyrouth",
            "code": "lb"
        },
        {
            "name": "Malaisie",
            "capital": "Kuala Lumpur",
            "code": "my"
        },
        {
            "name": "Maldives",
            "capital": "Malé",
            "code": "mv"
        },
        {
            "name": "Mongolie",
            "capital": "Oulan-Bator",
            "code": "mn"
        },
        {
            "name": "Népal",
            "capital": "Katmandou",
            "code": "np"
        },
        {
            "name": "Oman",
            "capital": "Mascate",
            "code": "om"
        },
        {
            "name": "Ouzbékistan",
            "capital": "Tachkent",
            "code": "uz"
        },
        {
            "name": "Pakistan",
            "capital": "Islamabad",
            "code": "pk"
        },
        {
            "name": "Philippines",
            "capital": "Manille",
            "code": "ph"
        },
        {
            "name": "Qatar",
            "capital": "Doha",
            "code": "qa"
        },
        {
            "name": "Singapour",
            "capital": "Singapour",
            "code": "sg"
        },
        {
            "name": "Sri Lanka",
            "capital": "Sri Jayawardenapura Kotte",
            "code": "lk"
        },
        {
            "name": "Syrie",
            "capital": "Damas",
            "code": "sy"
        },
        {
            "name": "Tadjikistan",
            "capital": "Douchanbé",
            "code": "tj"
        },
        {
            "name": "Taïwan",
            "capital": "Taipei",
            "code": "tw"
        },
        {
            "name": "Thaïlande",
            "capital": "Bangkok",
            "code": "th"
        },
        {
            "name": "Timor oriental",
            "capital": "Dili",
            "code": "tl"
        },
        {
            "name": "Turkménistan",
            "capital": "Achgabat",
            "code": "tm"
        },
        {
            "name": "Turquie",
            "capital": "Ankara",
            "code": "tr"
        },
        {
            "name": "Viêt Nam",
            "capital": "Hanoï",
            "code": "vn"
        },
        {
            "name": "Yémen",
            "capital": "Sanaa",
            "code": "ye"
        }
    ],
    "Afrique": [
        {
            "name": "Afrique du Sud",
            "capital": "Pretoria",
            "code": "za"
        },
        {
            "name": "Algérie",
            "capital": "Alger",
            "code": "dz"
        },
        {
            "name": "Angola",
            "capital": "Luanda",
            "code": "ao"
        },
        {
            "name": "Bénin",
            "capital": "Porto-Novo",
            "code": "bj"
        },
        {
            "name": "Botswana",
            "capital": "Gaborone",
            "code": "bw"
        },
        {
            "name": "Burkina Faso",
            "capital": "Ouagadougou",
            "code": "bf"
        },
        {
            "name": "Burundi",
            "capital": "Gitega",
            "code": "bi"
        },
        {
            "name": "Cameroun",
            "capital": "Yaoundé",
            "code": "cm"
        },
        {
            "name": "Cap-Vert",
            "capital": "Praia",
            "code": "cv"
        },
        {
            "name": "République centrafricaine",
            "capital": "Bangui",
            "code": "cf"
        },
        {
            "name": "Comores",
            "capital": "Moroni",
            "code": "km"
        },
        {
            "name": "République du Congo",
            "capital": "Brazzaville",
            "code": "cg"
        },
        {
            "name": "République démocratique du Congo",
            "capital": "Kinshasa",
            "code": "cd"
        },
        {
            "name": "Côte d'Ivoire",
            "capital": "Yamoussoukro",
            "code": "ci"
        },
        {
            "name": "Djibouti",
            "capital": "Djibouti",
            "code": "dj"
        },
        {
            "name": "Égypte",
            "capital": "Le Caire",
            "code": "eg"
        },
        {
            "name": "Érythrée",
            "capital": "Asmara",
            "code": "er"
        },
        {
            "name": "Éthiopie",
            "capital": "Addis-Abeba",
            "code": "et"
        },
        {
            "name": "Gabon",
            "capital": "Libreville",
            "code": "ga"
        },
        {
            "name": "Gambie",
            "capital": "Banjul",
            "code": "gm"
        },
        {
            "name": "Ghana",
            "capital": "Accra",
            "code": "gh"
        },
        {
            "name": "Guinée",
            "capital": "Conakry",
            "code": "gn"
        },
        {
            "name": "Guinée-Bissau",
            "capital": "Bissau",
            "code": "gw"
        },
        {
            "name": "Guinée équatoriale",
            "capital": "Malabo",
            "code": "gq"
        },
        {
            "name": "Kenya",
            "capital": "Nairobi",
            "code": "ke"
        },
        {
            "name": "Lesotho",
            "capital": "Maseru",
            "code": "ls"
        },
        {
            "name": "Liberia",
            "capital": "Monrovia",
            "code": "lr"
        },
        {
            "name": "Libye",
            "capital": "Tripoli",
            "code": "ly"
        },
        {
            "name": "Madagascar",
            "capital": "Antananarivo",
            "code": "mg"
        },
        {
            "name": "Malawi",
            "capital": "Lilongwe",
            "code": "mw"
        },
        {
            "name": "Mali",
            "capital": "Bamako",
            "code": "ml"
        },
        {
            "name": "Maroc",
            "capital": "Rabat",
            "code": "ma"
        },
        {
            "name": "Maurice",
            "capital": "Port-Louis",
            "code": "mu"
        },
        {
            "name": "Mauritanie",
            "capital": "Nouakchott",
            "code": "mr"
        },
        {
            "name": "Mozambique",
            "capital": "Maputo",
            "code": "mz"
        },
        {
            "name": "Namibie",
            "capital": "Windhoek",
            "code": "na"
        },
        {
            "name": "Niger",
            "capital": "Niamey",
            "code": "ne"
        },
        {
            "name": "Nigeria",
            "capital": "Abuja",
            "code": "ng"
        },
        {
            "name": "Ouganda",
            "capital": "Kampala",
            "code": "ug"
        },
        {
            "name": "Rwanda",
            "capital": "Kigali",
            "code": "rw"
        },
        {
            "name": "São Tomé-et-Principe",
            "capital": "Sao Tomé",
            "code": "st"
        },
        {
            "name": "Sénégal",
            "capital": "Dakar",
            "code": "sn"
        },
        {
            "name": "Seychelles",
            "capital": "Victoria",
            "code": "sc"
        },
        {
            "name": "Sierra Leone",
            "capital": "Freetown",
            "code": "sl"
        },
        {
            "name": "Somalie",
            "capital": "Mogadiscio",
            "code": "so"
        },
        {
            "name": "Soudan",
            "capital": "Khartoum",
            "code": "sd"
        },
        {
            "name": "Soudan du Sud",
            "capital": "Djouba",
            "code": "ss"
        },
        {
            "name": "Eswatini",
            "capital": "Mbabane",
            "code": "sz"
        },
        {
            "name": "Tanzanie",
            "capital": "Dodoma",
            "code": "tz"
        },
        {
            "name": "Tchad",
            "capital": "N'Djaména",
            "code": "td"
        },
        {
            "name": "Togo",
            "capital": "Lomé",
            "code": "tg"
        },
        {
            "name": "Tunisie",
            "capital": "Tunis",
            "code": "tn"
        },
        {
            "name": "Zambie",
            "capital": "Lusaka",
            "code": "zm"
        },
        {
            "name": "Zimbabwe",
            "capital": "Harare",
            "code": "zw"
        }
    ],
    "Amérique du Nord": [
        {
            "name": "Antigua-et-Barbuda",
            "capital": "Saint John's",
            "code": "ag"
        },
        {
            "name": "Bahamas",
            "capital": "Nassau",
            "code": "bs"
        },
        {
            "name": "Barbade",
            "capital": "Bridgetown",
            "code": "bb"
        },
        {
            "name": "Belize",
            "capital": "Belmopan",
            "code": "bz"
        },
        {
            "name": "Canada",
            "capital": "Ottawa",
            "code": "ca"
        },
        {
            "name": "Costa Rica",
            "capital": "San José",
            "code": "cr"
        },
        {
            "name": "Cuba",
            "capital": "La Havane",
            "code": "cu"
        },
        {
            "name": "Dominique",
            "capital": "Roseau",
            "code": "dm"
        },
        {
            "name": "États-Unis",
            "capital": "Washington",
            "code": "us"
        },
        {
            "name": "Grenade",
            "capital": "Saint-Georges",
            "code": "gd"
        },
        {
            "name": "Guatemala",
            "capital": "Guatemala",
            "code": "gt"
        },
        {
            "name": "Haïti",
            "capital": "Port-au-Prince",
            "code": "ht"
        },
        {
            "name": "Honduras",
            "capital": "Tegucigalpa",
            "code": "hn"
        },
        {
            "name": "Jamaïque",
            "capital": "Kingston",
            "code": "jm"
        },
        {
            "name": "Mexique",
            "capital": "Mexico",
            "code": "mx"
        },
        {
            "name": "Nicaragua",
            "capital": "Managua",
            "code": "ni"
        },
        {
            "name": "Panama",
            "capital": "Panama",
            "code": "pa"
        },
        {
            "name": "République dominicaine",
            "capital": "Saint-Domingue",
            "code": "do"
        },
        {
            "name": "Saint-Christophe-et-Niévès",
            "capital": "Basseterre",
            "code": "kn"
        },
        {
            "name": "Saint-Vincent-et-les-Grenadines",
            "capital": "Kingstown",
            "code": "vc"
        },
        {
            "name": "Sainte-Lucie",
            "capital": "Castries",
            "code": "lc"
        },
        {
            "name": "Salvador",
            "capital": "San Salvador",
            "code": "sv"
        },
        {
            "name": "Trinité-et-Tobago",
            "capital": "Port-d'Espagne",
            "code": "tt"
        }
    ],
    "Amérique du Sud": [
        {
            "name": "Argentine",
            "capital": "Buenos Aires",
            "code": "ar"
        },
        {
            "name": "Bolivie",
            "capital": "Sucre",
            "code": "bo"
        },
        {
            "name": "Brésil",
            "capital": "Brasilia",
            "code": "br"
        },
        {
            "name": "Chili",
            "capital": "Santiago",
            "code": "cl"
        },
        {
            "name": "Colombie",
            "capital": "Bogota",
            "code": "co"
        },
        {
            "name": "Équateur",
            "capital": "Quito",
            "code": "ec"
        },
        {
            "name": "Guyana",
            "capital": "Georgetown",
            "code": "gy"
        },
        {
            "name": "Paraguay",
            "capital": "Asuncion",
            "code": "py"
        },
        {
            "name": "Pérou",
            "capital": "Lima",
            "code": "pe"
        },
        {
            "name": "Suriname",
            "capital": "Paramaribo",
            "code": "sr"
        },
        {
            "name": "Uruguay",
            "capital": "Montevideo",
            "code": "uy"
        },
        {
            "name": "Venezuela",
            "capital": "Caracas",
            "code": "ve"
        }
    ],
    "Océanie": [
        {
            "name": "Australie",
            "capital": "Canberra",
            "code": "au"
        },
        {
            "name": "Fidji",
            "capital": "Suva",
            "code": "fj"
        },
        {
            "name": "Kiribati",
            "capital": "Tarawa",
            "code": "ki"
        },
        {
            "name": "Marshall",
            "capital": "Majuro",
            "code": "mh"
        },
        {
            "name": "Micronésie",
            "capital": "Palikir",
            "code": "fm"
        },
        {
            "name": "Nauru",
            "capital": "Yaren",
            "code": "nr"
        },
        {
            "name": "Nouvelle-Zélande",
            "capital": "Wellington",
            "code": "nz"
        },
        {
            "name": "Palaos",
            "capital": "Ngerulmud",
            "code": "pw"
        },
        {
            "name": "Papouasie-Nouvelle-Guinée",
            "capital": "Port Moresby",
            "code": "pg"
        },
        {
            "name": "Salomon",
            "capital": "Honiara",
            "code": "sb"
        },
        {
            "name": "Samoa",
            "capital": "Apia",
            "code": "ws"
        },
        {
            "name": "Tonga",
            "capital": "Nuku'alofa",
            "code": "to"
        },
        {
            "name": "Tuvalu",
            "capital": "Funafuti",
            "code": "tv"
        },
        {
            "name": "Vanuatu",
            "capital": "Port-Vila",
            "code": "vu"
        }
    ]
}

export {countryList};
