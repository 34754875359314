import React from "react";

import logo from "../../assets/images/logo.svg";
import data from "../../assets/data.json";

import "../../assets/css/encyclopedia/Encyclopedia.scss";
import {Country, getCountryFromName} from "../../utils/Country";

export default class Encyclopedia extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            countryContent: null
        }
    }

    render_country_content = (event) => {
        let countryName = event.target.innerText;
        this.country = getCountryFromName(countryName)
        this.setState({countryContent: this.getElementOfCountry(this.country)});
    }

    getElementOfCountry = (country) => {
        return <>
            <h2>{country.name}</h2>
            <p>Capitale: {country.capital}</p>
            <p>Code de pays : {country.code}</p>
            <img src={country.getFlagLink()} alt=""/>
        </>
    }


    render() {
        return <div id="encyclopedia-body">
            <div id="title-div">
                <img id="logo" src={logo} alt=""/>
                <h1 id="title">GeoQuiz</h1>
            </div>
            <header id="encyclopedia-header">
                <h2>Enclopédie</h2>
            </header>
            <div id="encyclopedia-content">
                <div id="content-bar">
                    <div id="research-input">
                        <input className="my-input" type="text"/>
                    </div>
                    <div id="content-list">
                        {Object.keys(data).map((continent, index) =>{
                            return <div key={index} className="encyclopedia-continent">
                                <h2 className="enclyco-continent-name">{continent}</h2>
                                <div id="encyclopedia-country">
                                    {data[continent].map((country, index) => {
                                        return <p onClick={this.render_country_content} key={index}>{country["name"]}</p>
                                    })}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
                <div id="encyclopedia-country-content">{this.state.countryContent}</div>
            </div>
        </div>
    }
}