import React from "react";

import "../../assets/css/flagle/FlaglePopUp.scss";

export default class FlaglePopUp extends React.Component {

    constructor(props) {
        super(props);
        this.country = props.country;
        this.flag = props.flag;
        this.score = props.score;
        this.nbGuess = props.nbGuess;
    }

    calculateSuccessRate = () => {
        let guessPerFlag = this.nbGuess / (this.score["streak"] + 1);
        return Math.round(100 - (guessPerFlag * 100 / 6));
    }

    goBackToMenu = () => {
        window.location.href = "/";
    }

    render() {
        return <div id="flagle-popup">
            <h2>Fin de la partie</h2>
            <div id="popup-content">
                <p>Score : {this.score["score"]}</p>
                <p>Série atteinte : {this.score["streak"]}</p>
                <p>Taux de réussite : {this.calculateSuccessRate()}%</p>
            </div>
            <div id="popup-flag">
                <p>Le pays qu'il fallait trouver est : {this.country}</p>
                <img src={"https://flagcdn.com/" + this.flag + ".svg"} alt=""/>
            </div>
            <button onClick={this.goBackToMenu} id="goBackToMenu" className="blue_button">Retour au menu</button>
        </div>
    }

}