const countryCodes = {
    "ad": "Andorre",
    "ae": "Émirats arabes unis",
    "af": "Afghanistan",
    "ag": "Antigua-et-Barbuda",
    "al": "Albanie",
    "am": "Arménie",
    "ao": "Angola",
    "ar": "Argentine",
    "at": "Autriche",
    "au": "Australie",
    "az": "Azerbaïdjan",
    "ba": "Bosnie-Herzégovine",
    "bb": "Barbade",
    "bd": "Bangladesh",
    "be": "Belgique",
    "bf": "Burkina Faso",
    "bg": "Bulgarie",
    "bh": "Bahreïn",
    "bi": "Burundi",
    "bj": "Bénin",
    "bn": "Brunei",
    "bo": "Bolivie",
    "br": "Brésil",
    "bs": "Bahamas",
    "bt": "Bhoutan",
    "bw": "Botswana",
    "by": "Biélorussie",
    "bz": "Belize",
    "ca": "Canada",
    "cd": "République démocratique du Congo",
    "cf": "République centrafricaine",
    "cg": "Congo",
    "ch": "Suisse",
    "ci": "Côte d'Ivoire",
    "cl": "Chili",
    "cm": "Cameroun",
    "cn": "Chine",
    "co": "Colombie",
    "cr": "Costa Rica",
    "cu": "Cuba",
    "cv": "Cap-Vert",
    "cy": "Chypre",
    "cz": "Tchéquie",
    "de": "Allemagne",
    "dj": "Djibouti",
    "dk": "Danemark",
    "dm": "Dominique",
    "do": "République dominicaine",
    "dz": "Algérie",
    "ec": "Équateur",
    "ee": "Estonie",
    "eg": "Égypte",
    "er": "Érythrée",
    "es": "Espagne",
    "et": "Éthiopie",
    "fi": "Finlande",
    "fj": "Fidji",
    "fm": "Micronésie",
    "fr": "France",
    "ga": "Gabon",
    "gb": "Royaume-Uni",
    "gd": "Grenade",
    "ge": "Géorgie",
    "gh": "Ghana",
    "gl": "Groenland",
    "gm": "Gambie",
    "gn": "Guinée",
    "gq": "Guinée équatoriale",
    "gr": "Grèce",
    "gt": "Guatemala",
    "gw": "Guinée-Bissau",
    "gy": "Guyana",
    "hk": "Hong Kong",
    "hn": "Honduras",
    "hr": "Croatie",
    "ht": "Haïti",
    "hu": "Hongrie",
    "id": "Indonésie",
    "ie": "Irlande",
    "il": "Israël",
    "in": "Inde",
    "iq": "Irak",
    "ir": "Iran",
    "is": "Islande",
    "it": "Italie",
    "jm": "Jamaïque",
    "jo": "Jordanie",
    "jp": "Japon",
    "ke": "Kenya",
    "kg": "Kirghizistan",
    "kh": "Cambodge",
    "ki": "Kiribati",
    "km": "Comores",
    "kn": "Saint-Christophe-et-Niévès",
    "kp": "Corée du Nord",
    "kr": "Corée du Sud",
    "kw": "Koweït",
    "kz": "Kazakhstan",
    "la": "Laos",
    "lb": "Liban",
    "lc": "Sainte-Lucie",
    "li": "Liechtenstein",
    "lk": "Sri Lanka",
    "lr": "Liberia",
    "ls": "Lesotho",
    "lt": "Lituanie",
    "lu": "Luxembourg",
    "lv": "Lettonie",
    "ly": "Libye",
    "ma": "Maroc",
    "mc": "Monaco",
    "md": "Moldavie",
    "me": "Monténégro",
    "mg": "Madagascar",
    "mh": "Iles Marshall",
    "mk": "Macédoine du Nord",
    "ml": "Mali",
    "mm": "Birmanie",
    "mn": "Mongolie",
    "mr": "Mauritanie",
    "mt": "Malte",
    "mu": "Maurice",
    "mv": "Maldives",
    "mw": "Malawi",
    "mx": "Mexique",
    "my": "Malaisie",
    "mz": "Mozambique",
    "na": "Namibie",
    "ne": "Niger",
    "ng": "Nigeria",
    "ni": "Nicaragua",
    "nl": "Pays-Bas",
    "no": "Norvège",
    "nr": "Nauru",
    //"np": "Népal",
    "nz": "Nouvelle-Zélande",
    "om": "Oman",
    "pa": "Panama",
    "pe": "Pérou",
    "pg": "Papouasie-Nouvelle-Guinée",
    "ph": "Philippines",
    "pk": "Pakistan",
    "pl": "Pologne",
    "pt": "Portugal",
    "pw": "Palaos",
    "py": "Paraguay",
    "qa": "Qatar",
    "ro": "Roumanie",
    "rs": "Serbie",
    "ru": "Russie",
    "rw": "Rwanda",
    "sa": "Arabie saoudite",
    "sb": "Iles Salomon",
    "sc": "Seychelles",
    "sd": "Soudan",
    "se": "Suède",
    "sg": "Singapour",
    "si": "Slovénie",
    "sk": "Slovaquie",
    "sl": "Sierra Leone",
    "sm": "Saint-Marin",
    "sn": "Sénégal",
    "so": "Somalie",
    "sr": "Suriname",
    "ss": "Soudan du Sud",
    "st": "Sao Tomé-et-Principe",
    "sv": "Salvador",
    "sy": "Syrie",
    "sz": "Eswatini",
    "td": "Tchad",
    "tg": "Togo",
    "th": "Thaïlande",
    "tj": "Tadjikistan",
    "tl": "Timor oriental",
    "tm": "Turkménistan",
    "tn": "Tunisie",
    "to": "Tonga",
    "tr": "Turquie",
    "tt": "Trinité-et-Tobago",
    "tv": "Tuvalu",
    "tw": "Taïwan",
    "tz": "Tanzanie",
    "ua": "Ukraine",
    "ug": "Ouganda",
    "us": "États-Unis",
    "uy": "Uruguay",
    "uz": "Ouzbékistan",
    "va": "Vatican",
    "vc": "Saint-Vincent-et-les-Grenadines",
    "ve": "Venezuela",
    "vn": "Viêt Nam",
    "vu": "Vanuatu",
    "ws": "Samoa",
    "xk": "Kosovo",
    "ye": "Yémen",
    "za": "Afrique du Sud",
    "zm": "Zambie",
    "zw": "Zimbabwe"
}

export default countryCodes;