import React from "react";
import ReactDOM from "react-dom/client";
import {getNumberOfDifferentColor, isSimilarColor, pixelFromList} from "./FlagleColor";

import "../../assets/css/flagle/FlaglePage.scss";

import logo from "../../assets/images/logo.svg";
import countryCodes from "./FlagleCountryCode";
import FlaglePopUp from "./FlaglePopUp";

export default class FlaglePage extends React.Component {

    country
    flagCode
    found = false
    haveGuessed = false
    popUp = false
    score = 0
    flagInARow = 0
    nbGuess = 0;
    tentatives = 0
    response = []
    countryPixel = []
    goodPixel = []

    setup = () => {
        let keys = Object.keys(countryCodes);
        let randomIndex = Math.floor(Math.random() * keys.length);
        this.country = countryCodes[keys[randomIndex]];
        this.flagCode = keys[randomIndex];
        //this.country = "Népal"
        //this.flagCode = "np"
        this.tentatives = 0
        this.response = []
        this.countryPixel = []
        this.goodPixel = []
        this.found = false
        if (!document.getElementById("nextButton").classList.contains("dontclick")) document.getElementById("nextButton").classList.add("dontclick")
        this.haveGuessed = false
        const canvas = document.getElementById("flagCanvas");
        const ctx = canvas.getContext("2d", { willReadFrequently: true});
        let imgData = ctx.getImageData(0, 0, 400, 267);
        const color = [36, 48, 71, 255]
        for (let i = 0; i < imgData.data.length; i++) {
            imgData.data[i] = color[i % 4];
        }
        ctx.putImageData(imgData, 0, 0);
    }

    setupImgPixel = () => {
        const canvas = document.getElementById("inputCanvas");
        const ctx = canvas.getContext("2d", { willReadFrequently: true});
        let img = new Image();
        img.crossOrigin = "anonymous";
        img.src = `https://flagcdn.com/${this.flagCode}.svg`
        img.onload = () => {
            ctx.drawImage(img, 0, 0, 400, 267);
            img.style.display = "none";
            let imgData = ctx.getImageData(0, 0, 400, 267);
            for (let i = 0; i < imgData.data.length; i+=4) {
                this.countryPixel.push(pixelFromList(imgData.data, i));
            }
        }
    }

    componentDidMount() {
        this.setup();
        this.setupImgPixel()
        //this.endFlag(false)
    }


    getCodeFromName = (name) => {
        for (let code in countryCodes) {
            if (countryCodes[code] === name) {
                return code;
            }
        }
        return null;
    }

    /*isSamePixel = (pixel1, pixel2) => {
        let sum = Math.abs(pixel1.r - pixel2.r) + Math.abs(pixel1.g - pixel2.g) + Math.abs(pixel1.b - pixel2.b);
        return sum < 150;
    }*/

     guessClick = () => {
         if (this.haveGuessed || this.popUp) return
        if (this.tentatives >= 6) {
            setTimeout(() => {
                this.endFlag(false)
            }, 5000)
        }
        this.haveGuessed = true;
        let elt = document.getElementById("flagleInput");
         let guess = elt.value;
        let guessPixel = []
        let code = this.getCodeFromName(guess);
        if (code === null){
            this.haveGuessed = false
            return;
        }
        let flag = document.getElementById("flagCanvas");
        const canvas = document.getElementById("inputCanvas");
         const ctx = canvas.getContext("2d", { willReadFrequently: true});
         let img = new Image();
        img.crossOrigin = "anonymous";
        img.src = `https://flagcdn.com/${code}.svg`
         img.onload = () => {
            ctx.drawImage(img, 0, 0, 400, 267);
            img.style.display = "none";
            flag.style.opacity = "0"
            canvas.style.opacity = "1"
            setTimeout(() => {
                flag.style.display = "none"
                canvas.style.display = "block"
            }, 500)
            let imgData = ctx.getImageData(0, 0, 400, 267);
            for (let i = 0; i < imgData.data.length; i+=4) {
                guessPixel.push(pixelFromList(imgData.data, i));
            }
            let goodPixelNumber = 0
            let maxPixel = Math.min(guessPixel.length, this.countryPixel.length);
            for (let i = 0; i < guessPixel.length; i++) {
                let p1 = guessPixel[i];
                let p2 = this.countryPixel[i];
                if (isSimilarColor(p1, p2)) {
                    goodPixelNumber++;
                    if (this.goodPixel.includes(i)) continue
                    this.goodPixel.push(i);
                }
            }
            setTimeout(() => {
                flag.style.opacity = "1"
                canvas.style.opacity = "0"
                setTimeout(() => {
                    flag.style.display = "block"
                    canvas.style.display = "none"
                }, 500)
                this.updateCanvas()
                this.nbGuess++;
                let percentage = Math.round(goodPixelNumber * 100 / maxPixel);
                let userResponse = {
                    "name": guess,
                    "percentage": percentage,
                    "code": code
                }
                this.response.push(userResponse);
                this.updateTable()
                this.tentatives++;
                elt.value = ""
                if (guess === this.country) {
                    this.endFlag(true)
                    this.found = true;
                    document.getElementById("nextButton").classList.remove("dontclick")
                } else if (this.tentatives >= 6) {
                    setTimeout(() => {
                        this.endFlag(false)
                    }, 2000)
                }
                this.haveGuessed = false
            }, 2000)
        }
    }

    updateTable = () => {
        let divs = document.getElementsByClassName("responseDiv");
        for (let i = 0; i < this.response.length; i++) {
            let div = divs[i];
            let ps = div.children;
            if (this.response[i].name === this.country) div.classList.add("correct")
            ps[0].innerHTML = this.response[i].name;
            ps[1].innerHTML = this.response[i].percentage + "%";
            ps[2].innerHTML = `<img src="https://flagcdn.com/${this.response[i].code}.svg" width="50" alt=""/>`;
            //ps[2].src = `https://flagcdn.com/${this.response[i].code}.svg`;
            //ps[2].style.width = "50"
        }
        /*for (let i = 0; i < this.response.length; i++) {
            let tr = trs[i];
            let tds = tr.children;
            if (this.response[i].name === this.country) tr.classList.add("correct")
            tds[0].innerHTML = this.response[i].name;
            tds[1].innerHTML = this.response[i].percentage + "%";
            tds[2].innerHTML = `<img src="https://flagcdn.com/${this.response[i].code}.svg" width="50" alt=""/>`;
        }*/
    }
    updateCanvas = () => {
        const canvas = document.getElementById("flagCanvas");
        const ctx = canvas.getContext("2d", { willReadFrequently: true});
        let imgData = ctx.getImageData(0, 0, 400, 267);
        for (let pixel of this.goodPixel) {
            imgData.data[pixel*4] = this.countryPixel[pixel].r;
            imgData.data[pixel*4+1] = this.countryPixel[pixel].g;
            imgData.data[pixel*4+2] = this.countryPixel[pixel].b;
            imgData.data[pixel*4+3] = this.countryPixel[pixel].a;
        }
        ctx.putImageData(imgData, 0, 0);
    }

    autoCompleteInput = (event) => {
         if (this.haveGuessed) {
             if (event.type === "input") {
                 event.preventDefault()
             }
             return
         }
        const input = document.getElementById("flagleInput");
        const countryAutocomplete = document.getElementById("countryAutocomplete");
        countryAutocomplete.innerHTML = "";
        countryAutocomplete.style.display = "block";
        let value = input.value;
        let keys = Object.values(countryCodes);
        let filtered = keys.filter((country) => {
            return country.toLowerCase().startsWith(value.toLowerCase());
        })
        filtered.sort((a, b) => { return a < b ? -1 : 1});
        for (let country of filtered) {
            let div = document.createElement("div");
            div.classList.add("countryAutocompleteElt")
            div.innerHTML = country;
            countryAutocomplete.appendChild(div);
            div.onclick = () => {
                input.value = country;
                countryAutocomplete.style.display = "none";
            }
        }

    }

    blurEvent = () => {
        const countryAutocomplete = document.getElementById("countryAutocomplete");
        let hover = document.querySelectorAll(":hover");
        for (let h of hover) {
            if (h.classList.contains("countryAutocompleteElt")) {
                return
            }
        }
        countryAutocomplete.style.display = "none";
    }

    revealFlag = () => {
        let responseDiv = document.getElementById("flagle-response");
        responseDiv.innerText = "Le pays qu'il fallait trouver est : "+ this.country;
        let flag = document.getElementById("flagCanvas");
        const ctx = flag.getContext("2d", { willReadFrequently: true});
        let imgData = ctx.getImageData(0, 0, 400, 267);
        for (let i = 0; i < imgData.data.length; i+=4) {
            imgData.data[i] = this.countryPixel[i/4].r;
            imgData.data[i+1] = this.countryPixel[i/4].g;
            imgData.data[i+2] = this.countryPixel[i/4].b;
            imgData.data[i+3] = this.countryPixel[i/4].a;
        }
        ctx.putImageData(imgData, 0, 0);
    }

    endFlag = (found) => {
         if (found) {
            this.flagInARow++
            this.score += Math.round(getNumberOfDifferentColor(this.countryPixel) * 15 / this.tentatives)
            this.forceUpdate()
             return
         }
         if (document.getElementsByClassName("popUpShowed").length > 0) return
         this.popUp = true
        document.getElementById("flagle-body").classList.add("popUpShow")
         let score = {"score": this.score, "streak": this.flagInARow}
         let elt = <FlaglePopUp flag={this.flagCode} country={this.country} score={score} nbGuess={this.nbGuess} />
         let div = document.createElement("div");
         div.classList.add("popupShowed");
         ReactDOM.createRoot(div).render(elt);
         document.body.appendChild(div);
    }

    nextFlag = (e) => {
         if (!this.found) {
             e.preventDefault()
             return
         }
         this.setup();
         this.setupImgPixel();
         this.forceUpdate();
         let aElt = document.getElementsByClassName("responseDiv");
         for (let elt of aElt) {
             elt.classList.remove("correct")
             for (let child of elt.children) {
                 child.innerHTML = ""
             }
         }
    }

    render() {
        const forList = []
        for (let i = 0; i < 6; i++) {
            let inside = <>
                <p className="nameInputTable"></p>
                <p className="percentInputTable"></p>
                <div className="imgInputTable"></div>
            </>
            forList.push(<div className="responseDiv" key={i}>{inside}</div>)
        }
        return <div id="flagle-body">
            <div id="title-div">
                <img id="logo" src={logo} alt=""/>
                <h1 id="title">GeoQuiz</h1>
            </div>
            <div id="flagle">
                <div id="flagle-header">
                    <h2>Flagle</h2>
                    <div id="flagle-header-content">
                        <p>Score actuel : {this.score}</p>
                        <p>Série en cours : {this.flagInARow}</p>
                    </div>
                </div>
                <div id="flagle-response"/>
                <div id="flagle-content">
                    <canvas id="flagCanvas" width="400" height="267"/>
                    <canvas id="inputCanvas" width="400" height="267"/>
                    <div id="flagle-input">
                        <div id="list-input">
                            {forList}
                        </div>
                        <input onBlur={this.blurEvent} onFocus={this.autoCompleteInput} onInput={this.autoCompleteInput} id="flagleInput" className="my-input" type="text"
                               placeholder="Votre réponse" autoComplete="off"/>
                        <div id="countryAutocomplete"/>
                    </div>
                    <div id="flagle-buttons">
                        <button id="guessButton" className="blue_button" onClick={this.guessClick}>Guess</button>
                        <button id="nextButton" className={((!this.found) ? "dontclick" : "") + " gray_button"} onClick={this.nextFlag}>Suivant</button>
                    </div>
                </div>
            </div>
        </div>
    }
}