class Pixel {
    constructor(r, g, b, a) {
        this.r = r;
        this.g = g;
        this.b = b;
        this.a = a;
        this.#calculateHSV()
    }

    #calculateHSV() {
        let dR = this.r / 255;
        let dG = this.g / 255;
        let dB = this.b / 255;
        let cMax = Math.max(dR, dG, dB);
        let cMin = Math.min(dR, dG, dB);
        let delta = cMax - cMin;
        this.v = Math.round(cMax * 100);
        this.s = cMax === 0 ? 0 : Math.round(delta / cMax * 100);
        this.h = delta === 0 ? 0 : Math.round(this.#calculateHue(dR, dG, dB, cMax, delta));
    }

    #calculateHue(dR, dG, dB, cMax, delta) {
        switch (cMax) {
            case dR:
                return 60 * (((dG - dB) / delta) % 6);
            case dG:
                return 60 * ((dB - dR) / delta + 2);
            case dB:
                return 60 * ((dR - dG) / delta + 4);
            default:
                return 0;
        }
    }

    printRGB() {
        console.log(`RGB: (${this.r}, ${this.g}, ${this.b})`);
    }

    printHSV() {
        console.log(`HSV: (${this.h}°, ${this.s}%, ${this.v}%)`);
    }
}

function pixelFromList(list, i=0) {
    return new Pixel(list[i], list[i + 1], list[i + 2], list[i + 3]);
}

function pixelFromCode(code) {
    if (code.startsWith("#")) code = code.substring(1, code.length)
    let r = parseInt(code.substring(0, 2), 16);
    let g = parseInt(code.substring(2, 4), 16);
    let b = parseInt(code.substring(4, 6), 16);
    return new Pixel(r, g, b, 255);
}

function isSimilarColor(pixel1, pixel2) {
    let hueThreshold = 30;
    let saturationThreshold = 50;
    let valueThreshold = 50;

    let hueDiff = Math.abs(pixel1.h - pixel2.h);
    let saturationDiff = Math.abs(pixel1.s - pixel2.s);
    let valueDiff = Math.abs(pixel1.v - pixel2.v);

    /*console.log(`Saturation diff: ${saturationDiff}`);
    console.log(`Value diff: ${valueDiff}`);
    console.log(`Hue diff: ${hueDiff}`);*/

    return hueDiff < hueThreshold && saturationDiff < saturationThreshold && valueDiff < valueThreshold;
}

function getNumberOfDifferentColor(pixelList) {
    let colorList = []
    for (let pixel of pixelList) {
        let found = false;
        for (let color of colorList) {
            if (isSimilarColor(pixel, color)) {
                found = true;
                break;
            }
        }
        if (!found) colorList.push(pixel);
    }
    return colorList.length - Math.round(colorList.length / 3) + (Math.round(colorList.length / 3) === 0 ? 1 : 0);
}

function main() {
    let pixel1 = pixelFromCode("#FFB81C");
    let pixel2 = pixelFromCode("#c8102e")
    pixel1.printHSV()
    pixel2.printHSV()
    //let pixel3 = pixelFromCode("#0d420a");
    console.log(isSimilarColor(pixel1, pixel2))
}

//main()

export {Pixel, pixelFromList, pixelFromCode, isSimilarColor, getNumberOfDifferentColor}