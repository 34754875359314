import React from "react";

import logo from "../../assets/images/logo.svg";
import data from "../../assets/data.json"

import "../../assets/css/capital-guess/Game.scss";

export default class Game extends React.Component {

    actual
    regions;
    questions;
    userResponse = [];
    correctError;
    actualIndex = 1;
    nbQuestions;
    wrong = [];
    hint = -1;
    isConfirmed = false;

    constructor(props) {
        super(props);
        this.regions = props.region;
        this.nbQuestions = this.getNumberOfQuestions();
        this.questions = this.generateQuestion();
        this.correctError = props.correctError;

        // eslint-disable-next-line react-hooks/rules-of-hooks
        document.addEventListener("keydown", this.enterConfirm);
    }

    getNumberOfQuestions = () => {
        let nmb = 0;
        for (let region of this.regions) {
            nmb += data[region].length;
        }
        return nmb;
    }

    generateQuestion = () => {
        let allCountry = []
        let questions = []
        let generateNumber = (max) => {
            let number = Math.floor(Math.random() * max);
            if (questions.includes(allCountry[number])) {
                return generateNumber(max);
            }
            return number;
        }
        for (let region of this.regions) {
            allCountry = allCountry.concat(data[region]);
        }
        for (let i = 0; i < this.nbQuestions; i++) {
            let number = generateNumber(allCountry.length);
            questions.push(allCountry[number]);
        }
        return questions;
    }

    componentDidMount() {
        setTimeout(() => {
            this.setProgressBar();
        }, 200)
        this.actual = this.questions[0];
        //this.actual = {"name": "Sri Lanka", "capital": "Sri Jayawardenapura Kotte"}
        this.updateVisual();
    }

    updateVisual = () => {
        let actualElt = document.getElementById("actual")
        let actualIndexElt = document.getElementById("actualIndex")
        let sizeIndexElt = document.getElementById("nbQuestions")
        let hintDiv = document.getElementById("hintElt")
        let hintChar = document.getElementById("hintChar")
        hintChar.innerText = this.actual["capital"].length;
        hintDiv.innerHTML = "";
        for (let i = 0; i < this.actual["capital"].length; i++) {
            let capital = this.actual["capital"];
            let elt = document.createElement("p");
            elt.innerText = [" ", "-", "'"].includes(capital[i]) || this.hint > i ? capital[i] : "_";
            hintDiv.appendChild(elt);
        }
        let hintDivElt = document.getElementById("hintDiv");
        if (this.hint >= 0) {
            hintDivElt.classList.remove("hidden");
        }  else if (!hintDivElt.classList.contains("hidden") && this.hint === -1) {
            hintDivElt.classList.add("hidden");
        }
        actualElt.innerText = this.actual["name"];
        actualIndexElt.innerText = this.actualIndex + "";
        sizeIndexElt.innerText = this.nbQuestions + "";
        this.setProgressBar();
    }

    comeBackToMenu = () => {
        window.location.reload();
    }

    setProgressBar = () => {
        let elt = document.getElementById("progress-bar")
        let percentage = 100 * this.actualIndex / this.nbQuestions;
        elt.style.width = percentage + "%"
    }

    nextQuestion = () => {
        this.actualIndex++;
        if (this.actualIndex > this.nbQuestions) {
            if (this.wrong.length === 0 || !this.correctError) {
                let event = new CustomEvent("endGame", {
                    detail: {"questions": this.questions, "userResponse": this.userResponse}
                })
                window.dispatchEvent(event);
                return;
            }
            this.questions = this.wrong;
            this.actualIndex = 0;
            this.nbQuestions = this.wrong.length;
            this.wrong = [];
            this.nextQuestion();
        } else {
            this.actual = this.questions[this.actualIndex - 1];
            this.isConfirmed = false;
            this.hint = -1;
            this.updateVisual();
            let input = document.getElementById("response");
            input.className = ""
            input.value = "";
        }
    }

    confirmResponse = () => {
        if (!this.isConfirmed) {
            let input = document.getElementById("response");
            let response = input.value.toLowerCase();
            let capital = this.actual["capital"].toLowerCase();
            let correct = response === capital;
            let alreadyInUserResponse = () => {
                for (let response of this.userResponse) {
                    if (response["country"] === this.actual["name"]) {
                        return true;
                    }
                }
                return false;
            }
            this.isConfirmed = true;
            input.classList.add(correct ? "good" : "bad");
            input.value = this.actual["capital"];
            if (!alreadyInUserResponse()) {
                this.userResponse.push({"country": this.actual["name"], "response": response, "correct": correct});
            }
            if (!correct) {
                this.wrong.push(this.actual);
            }
        } else {
            this.nextQuestion();
        }
    }

    inputPress = (event) => {
        if (this.isConfirmed) {
            event.preventDefault();
        }
    }

    addHint = () => {
        if (this.isConfirmed) return;
        this.hint++;
        this.updateVisual()
    }

    enterConfirm = (event) => {
        if (event.key === "Enter") {
            this.confirmResponse();
        }
    }

    render() {
        return (<>
            <div onClick={this.comeBackToMenu} id="title-div">
                <img id="logo" src={logo} alt=""/>
                <h1 id="title">GeoQuiz</h1>
            </div>
            <div id="game">
                <div id="advancement">
                    <p>Question <span id="actualIndex">{this.actualIndex}</span>/<span id="nbQuestions">{this.nbQuestions}</span></p>
                    <div onClick={this.test} id="progress">
                        <div id="progress-bar"></div>
                    </div>
                </div>
                <div id="question">
                    <h2>Quelle est la capitale de : <span id="actual">{this.actual}</span> ?</h2>
                    <div id="input-question">
                        <input onKeyPress={this.inputPress} id="response" placeholder="Votre réponse" type="text"/>
                    </div>
                    <div id="buttons">
                        <div>
                            <button onClick={this.confirmResponse} id="confirm">Confirmer</button>
                            <button onClick={this.addHint} id="hint">Indice</button>
                        </div>
                    </div>
                </div>
                <div id="hintDiv" className="hidden">
                    <h2>Indice (<span id="hintChar">0</span> charactère(s)) :</h2>
                    <div id="hintElt"></div>
                </div>
            </div>
            </>
        )
    }
}