import React from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import { Analytics } from "@vercel/analytics/react"

import "./assets/css/App.scss";

import HomePage from "./components/HomePage"

import CapitalPage from "./components/capital-guess/CapitalPage";
import FlaglePage from "./components/flagle/FlaglePage";
import Encyclopedia from "./components/encyclopedia/Encyclopedia";
import CartoClick from "./components/cartoclick/CartoClick";
import HowMuch from "./components/howmuch/HowMuch";

export default class App extends React.Component {
    router = createBrowserRouter([
        {
            path: "/",
            element: <HomePage />
        },
        {
            path: "/capital",
            element: <CapitalPage />
        },
        {
            path: "/flagle",
            element: <FlaglePage />
        },
        {
            path: "/encyclopedia",
            element: <Encyclopedia />
        },
        {
            path: "/cartoclick",
            element: <CartoClick />
        },
        {
            path: "howmuch",
            element: <HowMuch />
        }
    ]);

    render() {
        return <>
            <RouterProvider router={this.router}/>
            <Analytics />
            </>
    }
}