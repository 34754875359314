import React from "react";
import {Link} from "react-router-dom";

import "../assets/css/HomePage.scss";

export default class HomePage extends React.Component{

    render() {
        return <>
            <h1>Hello World</h1>
            <Link to="/capital">Capital Game</Link>
            <Link to="/flagle">Flagle Game</Link>
            <Link to="/encyclopedia">Encyclopédie</Link>
            <Link to="/cartoclick">CartoClick</Link>
        </>
    }

}