import React from "react";
import "leaflet/dist/leaflet.css"

import {MapContainer, TileLayer, GeoJSON} from "react-leaflet";
import {MapInstanceProvider} from "../../utils/MapInstanceProvider";
import {url, bounds} from "../../utils/CustomMap"
import logo from "../../assets/images/logo.svg";
import countryJSON from "../../assets/countries.json"
import surface from "../../assets/surface.json"
import data from "../../assets/data.json"
import "../../assets/css/cartoclick/CartoClick.scss";

export default class CartoClick extends React.Component {

    map
    allCountry = []
    colorBasic = ["#b34900", "#FF7C22"]
    colorValid = ["#00b34a", "#00FF7C"]
    colorInvalid = ["#b30000", "#FF2222"]
    playerScore = 0
    playerStreak = 0
    bestPlayerStreak = 0
    playerSelected = ""

    countriesLayer = []
    countriesFound = []
    countryClicked
    countryToFindString
    countryToFind
    countryState = "none"

    setup = () => {
        let randomIndex = Math.floor(Math.random() * this.allCountry.length)
        this.countryToFindString = this.allCountry[randomIndex]["name"]
        this.allCountry.splice(randomIndex, 1)
        let elt = document.getElementById("countryToFind")
        if (elt) {
            elt.innerText = this.countryToFindString
        }
        setTimeout(() => {
            this.countryToFind = this.countriesLayer.find(layer => layer.feature.properties.french_short === this.countryToFindString)
        }, 500)
    }

    componentDidMount() {
        for (let continent of Object.keys(data)) {
            //allCountries = allCountries.concat(data[continent])
            for (let country of data[continent]) {
                if (country.hasOwnProperty("cartoclick") && country["cartoclick"] === false) continue
                this.allCountry.push(country)
            }
        }
        this.setup()
    }

    setMap = (map) => {
        this.map = map
    }

    style = (e) => {
        let color = this.colorBasic
        let opacity = 0
        if (typeof e === "string") {
            color = (e === "valid") ? this.colorValid : this.colorInvalid
            opacity = 1
        }
        return {
            weight: 1,
            color: color[0],
            opacity: opacity,
            fillOpacity: opacity,
            fillColor: color[1]
        }
    }


    eachFeature = (feature, layer) => {
        if (!this.countriesLayer.includes(layer)) {
            this.countriesLayer.push(layer)
        }
        layer.on("mouseover", () => {
            if (this.countryState !== "none") return;
            if (this.countryClicked === layer) return;
            if (this.countriesFound.includes(layer)) return;
            layer.setStyle({
                fillOpacity: 1,
                opacity: 1
            })
        })
        layer.on("mouseout", () => {
            if (this.countryState !== "none") return;
            if (this.countryClicked === layer) return;
            if (this.countriesFound.includes(layer)) return;
            layer.setStyle({
                fillOpacity: 0,
                opacity: 0
            })
        })
        layer.on("click", () => {
            if (this.countryState !== "none") return;
            if (this.countriesFound.includes(layer)) return;
            if (this.countryClicked) {
                this.countryClicked.setStyle({
                    fillOpacity: 0,
                    opacity: 0
                })
            }
            this.countryClicked = layer
        })
    }

    valid = () => {
        if (!this.countryClicked) return;
        let name = this.countryClicked.feature.properties.french_short
        this.playerSelected = name
        this.countryState = (name === this.countryToFindString) ? "valid" : "invalid"
        this.countryClicked.setStyle(this.style(this.countryState))
        if (this.countryState === "invalid") {
            this.countryToFind.setStyle(this.style("valid"))
            this.playerStreak = 0
        } else {
            this.playerStreak++
            this.playerScore++
            if (this.playerStreak >= this.bestPlayerStreak) {
                this.bestPlayerStreak = this.playerStreak
            }
        }
        let score = document.getElementById("cartoclick-score")
        let streak = document.getElementById("cartoclick-streak")
        let selected = document.getElementById("cartoclick-selected")
        let bestStreak = document.getElementById("cartoclick-beststreak")
        score.innerText = this.playerScore + ""
        streak.innerText = this.playerStreak+""
        bestStreak.innerText = this.bestPlayerStreak + ""
        selected.innerText = this.playerSelected
        let englishName = this.countryToFind.feature.properties.name
        let surfaceCountry = surface.find(elt => elt["country"] === englishName)
        let zoom = 4
        if (surfaceCountry !== undefined) {
            surfaceCountry = surfaceCountry["area"]
            zoom = (surfaceCountry > 2970000) ? 2 : (surfaceCountry > 1500000) ? 3 : (surfaceCountry > 820000) ? 4 :  (surfaceCountry > 270000) ? 5 : (surfaceCountry > 100000) ? 6 : 7
        }
        // Change zoom in terms of distance
        let pos = [this.countryToFind.feature.properties.geo_point_2d["lat"], this.countryToFind.feature.properties.geo_point_2d["lon"]]
        let map = this.map
        console.log(zoom)
        map.flyTo(pos, zoom)
        //this.map.flyTo()
    }

    next = (e) => {
        if (this.countryState === "none") {
            e.preventDefault()
            return
        }
        this.countryState = "none"
        //this.countryClicked.setStyle(this.style())
        //this.countryToFind.setStyle(this.style())
        if (this.countryClicked === this.countryToFind) {
            this.countryClicked.setStyle(this.style("valid"))
        } else {
            this.countryToFind.setStyle(this.style("invalid"))
            this.countryClicked.setStyle(this.style())
        }
        this.countriesFound.push(this.countryToFind)
        this.countryClicked = undefined
        this.setup()
        let selected = document.getElementById("cartoclick-selected")
        selected.innerText = ""
    }


    render() {
        return (
            <div id="cartoclick-body">
                <div id="title-div">
                    <img id="logo" src={logo} alt=""/>
                    <h1 id="title">GeoQuiz</h1>
                </div>
                <div id="cartoclick">
                    <div id="cartoclick-text">
                        <h2>Vous devez trouver :</h2>
                        <p id="countryToFind">{this.countryToFind}</p>
                    </div>
                    <div id="cartoclick-center">
                        <div id="cartoclick-info">
                            <h2>Informations</h2>
                            <p><u>Score :</u> <span id="cartoclick-score">{this.playerScore}</span></p>
                            <p><u>Nombre de pays trouvé d'affilé :</u> <span id="cartoclick-streak">{this.playerStreak}</span></p>
                            <p><u>Meilleur streak actuel :</u> <span id="cartoclick-beststreak">{this.bestPlayerStreak}</span></p>
                            <p><u>Pays sélectionné :</u> <span id="cartoclick-selected">{this.playerSelected}</span></p>
                        </div>
                        <div id="cartoclick-map">
                            <MapContainer id="cartoclick-container"
                                          maxBounds={bounds}
                                          maxBoundsViscosity={1.0}
                                          center={[50, 10]}
                                          zoom={3}
                                          minZoom={2} maxZoom={7}
                                          scrollWheelZoom={true}
                                          zoomControl={false}
                            >
                                <TileLayer noWrap={true}
                                           url={url}
                                />
                                <GeoJSON data={countryJSON} style={this.style} onEachFeature={this.eachFeature}/>
                                <MapInstanceProvider setMap={this.setMap}/>
                            </MapContainer>
                        </div>
                    </div>
                </div>
                <div id="cartoclick-buttons">
                    <button id="cartoclick-valid" className="blue_button" onClick={this.valid}>Valider</button>
                    <button id="cartoclick-next" className="blue_button" onClick={this.next}>Suivant</button>
                </div>
            </div>
        )
    }
}