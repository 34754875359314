import {countryList} from "./CountryList";

class Country {

    #name;
    #code;
    #capital;

    constructor(name, code, capital) {
        this.#name = name;
        this.#code = code;
        this.#capital = capital;
    }

    get name() {
        return this.#name;
    }

    get code() {
        return this.#code;
    }

    get capital() {
        return this.#capital;
    }

    getFlagLink() {
        return `https://flagcdn.com/${this.code}.svg`;
    }
}

function getCountryFromJSON(json) {
    if (json === null) return null;
    return new Country(json.name, json.code, json.capital);
}

function getCountryFrom(value, filter) {
    return getCountryFromJSON(Object.values(countryList)
        .flat()
        .find(country => country[`${filter}`] === value) || null);
}

function getCountryFromName(name) {
    return getCountryFrom(name, "name");
}

function getCountryFromCode(code) {
    return getCountryFrom(code, "code");
}

export {Country, getCountryFromName, getCountryFromCode, getCountryFromJSON};
