import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import Game from "./components/capital-guess/Game"
import End from "./components/capital-guess/End"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

window.addEventListener('playGame', (event) => {
    root.render(<Game region={event.detail["region"]} correctError={event.detail["correctError"]}/>)
})

window.addEventListener("endGame", (event) => {
    root.render(<End questions={event.detail["questions"]} userResponse={event.detail["userResponse"]} />)
})

window.addEventListener("click", (event) => {
    if (event.target.id !== "title-div" && event.target.id !== "title") return;
    window.location.href = "/"
})