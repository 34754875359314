import React from "react";
import "leaflet/dist/leaflet.css"

import "../../assets/css/howmuch/HowMuch.scss"
import {MapContainer, TileLayer, GeoJSON} from "react-leaflet";
import {MapInstanceProvider} from "../../utils/MapInstanceProvider";
import {url, bounds} from "../../utils/CustomMap"
import logo from "../../assets/images/logo.svg";
import countryJSON from "../../assets/countries.json";

export default class HowMuch extends React.Component {

    map

    setMap = (map) => {
        this.map = map
    }

    render() {
        return <div id="howmuch">
            <div id="title-div">
                <img id="logo" src={logo} alt=""/>
                <h1 id="title">GeoQuiz</h1>
            </div>
            <div id="howmuch-content">
                <div id="howmuch-content">
                    <h2>How Much</h2>
                    <p>Sélectionner les pays que vous avez déjà visiter</p>
                </div>
                <div id="howmuch-map">
                    <MapContainer id="howmuch-container"
                                  maxBounds={bounds}
                                  maxBoundsViscosity={1.0}
                                  center={[50, 10]}
                                  zoom={3}
                                  minZoom={2} maxZoom={7}
                                  scrollWheelZoom={true}
                                  zoomControl={false}
                    >
                        <TileLayer noWrap={true}
                                   url={url}
                        />
                        <GeoJSON data={countryJSON} style={this.style} onEachFeature={this.eachFeature}/>
                        <MapInstanceProvider setMap={this.setMap}/>
                    </MapContainer>
                </div>
            </div>
        </div>
    }
}