import React from "react";

import data from "../../assets/data.json";
import random from "../../assets/images/random.jpg"
import logo from "../../assets/images/logo.svg"

import  "../../assets/css/capital-guess/CapitalPage.scss";

export default class CapitalPage extends React.Component {


    getRegionSelected = () => {
        let listRegions = []
        let regions = document.getElementById("continent-list");
        for (let region of regions.children) {
            if (region.className.includes("selected")) {
                listRegions.push(region.textContent);
            }
        }
        return listRegions;
    }

    continentClick = (event) => {
        let element = event.target
        if (element.nodeName !== "DIV") {
            element = element.parentElement
        }
        if (element.className.includes("selected") && this.getRegionSelected().length > 1) {
            element.className = "continent";
        } else {
            element.className = "continent selected";
        }
    }

    playGameButton = () => {
        let correctError = document.getElementById("validate-checkbox").checked;
        let regions = []
        let continents = document.getElementById("continent-list")
        for (let continent of continents.children) {
            if (continent.className.includes("selected")) {
                regions.push(continent.id)
            }
        }
        let event = new CustomEvent('playGame', {
            detail: {"region": regions, "correctError": correctError}
        })
        window.dispatchEvent(event)
    }

    render() {
        return (
            <div id="home-page">
                <div id="title-div">
                    <img id="logo" src={logo} alt=""/>
                    <h1 id="title">GeoQuiz</h1>
                </div>
                <div>
                    <h2 id="choose-continent">Choisissez un continent</h2>
                    <div id="continent-list">
                        {Object.keys(data).map((continent, index) => {
                            return (<div className="continent selected" onClick={this.continentClick} id={continent} key={index}>
                                <img className="continent-image" src={random} alt=""/>
                                <p>{continent}</p>
                            </div>)
                        })}
                    </div>
                    <div id="button-div">
                        <button onClick={this.playGameButton} id="play-button">Jouer</button>
                        <p>Trouver la capitale de ces pays</p>
                    </div>
                    <div id="error-div">
                        <p>Corriger les erreurs</p>
                        <input type="checkbox" id="validate-checkbox" defaultChecked/>
                    </div>
                </div>
            </div>
        )

    }

}