import React from "react";
import logo from "../../assets/images/logo.svg";

import "../../assets/css/capital-guess/End.scss";

export default class End extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        setTimeout(() => {
            this.updateVisual();
        }, 500)
    }

    comeBackToMenu = () => {
        window.location.reload();
    }

    updateVisual = () => {
        let bar = document.getElementById("percentage-progress-bar");
        let correctAnswer = 0;
        let correctAnswerDiv = document.getElementById("correctAnswer");
        let maxCorrectAnswerDiv = document.getElementById("maxCorrectAnswer");
        let percentCorrectAnswerDiv = document.getElementById("percentCorrectAnswer");
        for (let i = 0; i < this.props.questions.length; i++) {
            if (this.props.userResponse[i]["correct"]) correctAnswer++;
        }
        let round = (f, r) => {
            return Math.round(f * Math.pow(10, r)) / Math.pow(10, r);
        }
        let percentage = correctAnswer / this.props.questions.length * 100;
        bar.style.width = percentage + "%";
        correctAnswerDiv.innerText = correctAnswer + "";
        percentCorrectAnswerDiv.innerText = round(percentage, 0) + "";
    }

    render() {
        return (
            <>
                <div onClick={this.comeBackToMenu} id="title-div">
                    <img id="logo" src={logo} alt=""/>
                    <h1 id="title">GeoQuiz</h1>
                </div>
                <div id="end">
                    <h2 id="recap">Récapitulatif</h2>
                    <div id="percentageDiv">
                        <p>Vous avez eu <span id="correctAnswer">0</span>/<span id="maxCorrectAnswer">{this.props.questions.length}</span> soit <span id="percentCorrectAnswer">0</span>% de bonne réponse</p>
                        <div id="percentage-progress">
                            <div id="percentage-progress-bar"></div>
                        </div>
                    </div>
                    <div id="tableDiv">
                        <table id="scoreTable">
                            <thead>
                                <tr className="trScore">
                                    <th>#</th>
                                    <th>Nom du pays</th>
                                    <th>Votre réponse</th>
                                    <th>Réponse correct</th>
                                </tr>
                            </thead>
                            <tbody id="scoreBody">
                                {this.props.questions.map((question, index) => {
                                    return (
                                        <tr key={index} className={(this.props.userResponse[index]["correct"] ? "good" : "bad") + " trScore"}>
                                            <td>{index+1}</td>
                                            <td>{question["name"]}</td>
                                            <td>{
                                                this.props.userResponse[index]["response"] === "" ? "Aucune réponse" : this.props.userResponse[index]["response"]
                                            }</td>
                                            <td>{question["capital"]}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div id="comeBackMenu">
                        <button id="comeBackMenuButton" onClick={this.comeBackToMenu}>Retourner au menu</button>
                    </div>
                </div>
            </>
        );
    }

}