import React, { useEffect } from 'react';
import { useMapInstance } from './useMapInstance';

export function MapInstanceProvider({ setMap }) {
    const map = useMapInstance();

    useEffect(() => {
        setMap(map);
    }, [map, setMap]);

    return null;
}